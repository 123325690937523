import Index from "./index";
import { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import "ldrs/ring";
import { Alert } from "@mui/material";
import { hourglass } from "ldrs";
import { Modal, Button } from "react-bootstrap";

function Marche() {
  const [loading, setLoading] = useState(false);
  const [marche, setmarche] = useState([]);
  const [commune, setCommune] = useState([]);
  const [collecteur, setCollecteur] = useState([]);
  const [code, setcode] = useState("");
  const [nom, setnom] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [description, setDescription] = useState("");
  const [localite, setLocalite] = useState("");
  const [idmarche, setidmarche] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedmarche, setselectedmarche] = useState(null);
  const [selectedCommune, setselectedCommune] = useState(null);
  const [selectedCollecteur, setSelectedCollecteur] = useState(null);
  const [type, setType] = useState("");
  const [jour, setJour] = useState("");
  const [superficie, setSuperficie] = useState("");
  const [deleteMessage, setDeleteMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(""); // Message de succès
  const [errorMessage, setErrorMessage] = useState(""); //
  // Charger les données existantes
  useEffect(() => {
    fetch("https://fastapiimg.onrender.com/api/v1/all-marche/")
      .then((response) => response.json())
      .then((data) => setmarche(data))
      .catch((error) =>
        console.error("Erreur lors du chargement des données:", error)
      );
  }, []);
  useEffect(() => {
    fetch("https://fastapiimg.onrender.com/api/v1/all-collecteur/")
      .then((response) => response.json())
      .then((data) => setCollecteur(data))
      .catch((error) =>
        console.error("Erreur lors du chargement des données:", error)
      );
  }, []);
  useEffect(() => {
    fetch("https://fastapiimg.onrender.com/api/v1/all-commune/")
      .then((response) => response.json())
      .then((data) => setCommune(data))
      .catch((error) =>
        console.error("Erreur lors du chargement des données:", error)
      );
  }, []);

  // Simuler le chargement
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  // Ouvrir et fermer le modal
  const handleShow = (marche = null) => {
    if (marche) {
      setselectedmarche(marche); // Remplir les champs pour modification
      setidmarche(marche.id_marche); // Capturer l'ID pour la modification
      setcode(marche.code_marche);
      setnom(marche.nom_marche);
      setJour(marche.jour_marche); // Capturer
      setType(marche.type_marche);
      setDescription(marche.description);
      setLocalite(marche.localite);
      setLongitude(marche.longitude);
      setLatitude(marche.latitude);
      setSuperficie(marche.superficie);
      setselectedCommune(marche.commune);
      setSelectedCollecteur(marche.collecteur);
    } else {
      setselectedmarche(null); // Pour un nouvel ajout
      setidmarche(null); // Aucun ID pour un nouvel ajout
      setcode("");
      setnom("");
      setJour("");
      setType("");
      setDescription("");
      setLocalite("");
      setLongitude("");
      setLatitude("");
      setSuperficie("");
      setselectedCommune(null);
      setSelectedCollecteur(null);
    }
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  // Gérer le changement des inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "code") {
      setcode(value);
    } else if (name === "nom") {
      setnom(value);
    } else if (name === "localite") {
      setLocalite(value);
    } else if (name === "type") {
      setType(value);
    } else if (name === "jour") {
      setJour(value);
    } else if (name === "superficie") {
      setSuperficie(value);
    } else if (name === "longitude") {
      setLongitude(value);
    } else if (name === "latitude") {
      setLatitude(value);
    } else if (name === "description") {
      setDescription(value);
    } else if (name === "commune") {
      setCommune(value); // Mettre à jour la famille sélectionnée
    } else if (name === "collecteur") {
      setCollecteur(value); // Mettre à jour la famille sélectionnée
    }
  };

  // Gérer le changement du checkbox

  // Fonction pour ajouter ou modifier une famille de produit
  const soumettremarche = () => {
    const marcheData = {
      id_marche: idmarche, // Utiliser l'ID ici pour la modification
      code_marche: code,
      nom_marche: nom,
      type_marche: type,
      jour_marche: jour, // Si le checkbox est coché, mettre 1, sinon 0
      longitude: longitude, // Remplacez par la valeur appropriée
      latitude: latitude,
      superficie: superficie, // Un état par défaut
      date_creation: selectedmarche
        ? selectedmarche.date_enregistrement
        : new Date().toISOString().split("T")[0],
      description: description,
      localite: localite,
      commune: selectedCommune,
      id_personnel: "1",
      modifier_le: new Date().toISOString().split("T")[0],
      modifier_par: "admin",
      collecteur: selectedCollecteur, // Remplacez par la personne qui modifie
    };

    const url = selectedmarche
      ? `https://fastapiimg.onrender.com/api/v1/marche/update/${idmarche}/` // Utiliser l'ID ici
      : "https://fastapiimg.onrender.com/api/v1/marche/create/";

    const method = selectedmarche ? "PUT" : "POST";

    fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(marcheData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (selectedmarche) {
          // Mise à jour de la famille dans la liste
          setmarche(
            marche.map((f) => (f.id_marche === data.id_marche ? data : f))
          );
        } else {
          // Ajouter une nouvelle famille à la liste
          setmarche([...marche, data]);
        }
        // Réinitialiser les champs
        // Fermer le modal après soumission
        setSuccessMessage(
          selectedmarche ? "Modification réussie !" : "Ajout réussi !"
        );
        setErrorMessage("");
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);

        // Réinitialiser le formulaire et fermer le modal
        setselectedmarche(null); // Pour un nouvel ajout
        setidmarche(null);
        setType("");
        setJour(""); // Aucun ID pour un nouvel ajout
        setcode("");
        setnom("");
        setDescription("");
        setLocalite("");
        setLongitude("");
        setLatitude("");
        setSuperficie("");
        setselectedCommune(null);
        setSelectedCollecteur(null);
        setShowModal(false);
      })
      .catch((error) => {
        // Afficher le message d'erreur
        setErrorMessage("Échec de l'enregistrement.");
        setSuccessMessage("");
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
        console.error("Erreur lors de l'ajout ou de la modification:", error);
      });
  };
  const supprimermarche = (id) => {
    fetch(`https://fastapiimg.onrender.com/api/v1/marche/delete/${id}/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          // Supprimer la famille localement après succès
          setmarche(marche.filter((marche) => marche.id_marche !== id));
          setDeleteMessage("Suppression réussie !");
          setTimeout(() => {
            setDeleteMessage(""); // Masquer le message après 3 secondes
          }, 3000);
        } else {
          console.error(
            "Erreur lors de la suppression de la région naturelle:",
            response
          );
        }
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la suppression de la région naturelle:",
          error
        );
      });
  };

  // Gérer le clic sur "Supprimer"
  const handleDeleteClick = (id) => {
    if (
      window.confirm(
        "Êtes-vous sûr de vouloir supprimer cette famille de produits ?"
      )
    ) {
      supprimermarche(id);
    }
  };
  // Ajouter ces états pour la pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Nombre d'éléments par page

  // Calculer les indices des éléments à afficher
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = marche.slice(indexOfFirstItem, indexOfLastItem);

  // Fonction pour changer de page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Nombre total de pages
  const totalPages = Math.ceil(marche.length / itemsPerPage);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // occupe toute la hauteur de la page
            backgroundImage: "assets/img/icons/logo-simro.png",
          }}
        >
          <l-hourglass
            color={"#00a600"}
            loading={loading}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          ></l-hourglass>
        </div>
      ) : (
        <>
          <Index />
          <div className="content">
            <h3
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
                color: "gold",
              }}
            >
              Liste des marches
            </h3>
            {successMessage && (
              <Alert severity="success" sx={{ mb: 2 }}>
                {successMessage}
              </Alert>
            )}
            {errorMessage && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {errorMessage}
              </Alert>
            )}
            {deleteMessage && (
              <Alert severity="info" sx={{ mb: 2 }}>
                {deleteMessage}
              </Alert>
            )}
            <div
              id="tableExample3"
              data-list='{"valueNames":["name","email","age"],"page":5,"pagination":true}'
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div class="search-box mb-3">
                  <form className="position-relative">
                    <input
                      className="form-control search-input search form-control-sm"
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                    />
                    <svg
                      className="svg-inline--fa fa-magnifying-glass search-box-icon"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="magnifying-glass"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      data-fa-i2svg
                    >
                      <path
                        fill="currentColor"
                        d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                      />
                    </svg>
                    {/* <span class="fas fa-search search-box-icon"></span> Font Awesome fontawesome.com */}
                  </form>
                </div>
                <div>
                  <button
                    id="addBtn"
                    className="btn btn-primary"
                    onClick={() => setShowModal(true)}
                    style={{
                      backgroundColor: "#00a600",
                    }}
                  >
                    <svg
                      className="svg-inline--fa fa-plus me-2"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="plus"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      data-fa-i2svg
                    >
                      <path
                        fill="currentColor"
                        d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                      ></path>
                    </svg>
                    Nouveau marche
                  </button>
                </div>
              </div>
              <div
                className="table-responsive"
                style={{
                  backgroundColor: "#f8f9fa",
                  border: "1px solid #dee2e6",
                  boxShadow: "0 4px 8px green",
                  borderRadius: "5px",
                  padding: "20px",
                }}
              >
                <table
                  className="table table-striped table-sm fs-9 mb-0"
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                    borderCollapse: "collapse",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        className="sort border-top"
                        style={{
                          backgroundColor: "#fc8e38",
                          color: "white",
                          fontWeight: "700",
                          fontSize: "18px",
                          textAlign: "center",
                          borderBottom: "2px solid #dee2e6",
                        }}
                      >
                        Code
                      </th>
                      <th
                        className="sort border-top border-translucent ps-3"
                        style={{
                          backgroundColor: "#fc8e38",
                          color: "white",
                          fontWeight: "700",
                          fontSize: "18px",
                          textAlign: "center",
                          borderBottom: "2px solid #dee2e6",
                        }}
                      >
                        Nom marche
                      </th>
                      <th
                        className="sort border-top"
                        style={{
                          backgroundColor: "#fc8e38",
                          color: "white",
                          fontWeight: "700",
                          fontSize: "18px",
                          textAlign: "center",
                          borderBottom: "2px solid #dee2e6",
                        }}
                      >
                        Superficie
                      </th>
                      <th
                        className="sort border-top"
                        style={{
                          backgroundColor: "#fc8e38",
                          color: "white",
                          fontWeight: "700",
                          cursor: "pointer",
                          fontSize: "18px",
                          border: "none",
                          textAlign: "center",
                        }}
                      >
                        Localite
                      </th>
                      <th
                        className="sort border-top"
                        style={{
                          backgroundColor: "#fc8e38",
                          color: "white",
                          fontWeight: "700",
                          fontSize: "18px",
                          textAlign: "center",
                          borderBottom: "2px solid #dee2e6",
                        }}
                      >
                        Type Marche
                      </th>
                      <th
                        className="sort border-top"
                        style={{
                          backgroundColor: "#fc8e38",
                          color: "white",
                          fontWeight: "700",
                          fontSize: "18px",
                          textAlign: "center",
                          borderBottom: "2px solid #dee2e6",
                        }}
                      >
                        Jour
                      </th>
                      <th
                        className="sort border-top"
                        style={{
                          backgroundColor: "#fc8e38",
                          color: "white",
                          fontWeight: "700",
                          fontSize: "18px",
                          textAlign: "center",
                          borderBottom: "2px solid #dee2e6",
                        }}
                      >
                        Longitude
                      </th>
                      <th
                        className="sort border-top"
                        style={{
                          backgroundColor: "#fc8e38",
                          color: "white",
                          fontWeight: "700",
                          fontSize: "18px",
                          textAlign: "center",
                          borderBottom: "2px solid #dee2e6",
                        }}
                      >
                        Latitude
                      </th>
                      <th
                        className="sort border-top"
                        style={{
                          backgroundColor: "#fc8e38",
                          color: "white",
                          fontWeight: "700",
                          fontSize: "18px",
                          textAlign: "center",
                          borderBottom: "2px solid #dee2e6",
                        }}
                      >
                        Description
                      </th>
                      <th
                        className="sort border-top"
                        style={{
                          backgroundColor: "#fc8e38",
                          color: "white",
                          fontWeight: "700",
                          fontSize: "18px",
                          textAlign: "center",
                          borderBottom: "2px solid #dee2e6",
                        }}
                      >
                        Commune
                      </th>
                      <th
                        className="sort border-top"
                        style={{
                          backgroundColor: "#fc8e38",
                          color: "white",
                          fontWeight: "700",
                          fontSize: "18px",
                          textAlign: "center",
                          borderBottom: "2px solid #dee2e6",
                        }}
                      >
                        Collecteur
                      </th>
                      <th
                        className="sort text-end align-middle pe-0 border-top"
                        scope="col"
                        style={{
                          backgroundColor: "red",
                          color: "white",
                          fontWeight: "700",
                          cursor: "pointer",
                          fontSize: "18px",
                          border: "none",
                          textAlign: "center",
                        }}
                      >
                        ACTION
                      </th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {currentItems.length > 0 ? (
                      currentItems.map((marche, index) => (
                        <tr
                          key={index}
                          style={{ borderBottom: "1px solid #dee2e6" }}
                        >
                          <td className="align-middle ps-3 name">
                            {marche.code_marche}
                          </td>
                          <td
                            className="align-middle ps-3 name"
                            style={{
                              textAlign: "center",
                              border: "1px solid #dee2e6", // Ajout de bordures autour des cellules
                              padding: "10px",
                            }}
                          >
                            {marche.nom_marche}
                          </td>
                          <td
                            className="align-middle modifier-par"
                            style={{
                              textAlign: "center",
                              border: "1px solid #dee2e6", // Ajout de bordures autour des cellules
                              padding: "10px",
                            }}
                          >
                            {marche.superficie || "N/A"}
                          </td>
                          <td
                            className="align-middle modifier-par"
                            style={{
                              textAlign: "center",
                              border: "1px solid #dee2e6", // Ajout de bordures autour des cellules
                              padding: "10px",
                            }}
                          >
                            {marche.localite || "N/A"}
                          </td>
                          <td
                            className="align-middle modifier-par"
                            style={{
                              textAlign: "center",
                              border: "1px solid #dee2e6", // Ajout de bordures autour des cellules
                              padding: "10px",
                            }}
                          >
                            {marche.type_marche || "N/A"}
                          </td>
                          <td
                            className="align-middle modifier-par"
                            style={{
                              textAlign: "center",
                              border: "1px solid #dee2e6", // Ajout de bordures autour des cellules
                              padding: "10px",
                            }}
                          >
                            {marche.jour_marche || "N/A"}
                          </td>
                          <td
                            className="align-middle modifier-par"
                            style={{
                              textAlign: "center",
                              border: "1px solid #dee2e6", // Ajout de bordures autour des cellules
                              padding: "10px",
                            }}
                          >
                            {marche.longitude || "N/A"}
                          </td>
                          <td
                            className="align-middle modifier-par"
                            style={{
                              textAlign: "center",
                              border: "1px solid #dee2e6", // Ajout de bordures autour des cellules
                              padding: "10px",
                            }}
                          >
                            {marche.latitude || "N/A"}
                          </td>
                          <td
                            className="align-middle modifier-par"
                            style={{
                              textAlign: "center",
                              border: "1px solid #dee2e6", // Ajout de bordures autour des cellules
                              padding: "10px",
                            }}
                          >
                            {marche.description || "N/A"}
                          </td>
                          <td
                            className="align-middle modifier-par"
                            style={{
                              textAlign: "center",
                              border: "1px solid #dee2e6", // Ajout de bordures autour des cellules
                              padding: "10px",
                            }}
                          >
                            {marche.commune}
                          </td>
                          <td
                            className="align-middle modifier-par"
                            style={{
                              textAlign: "center",
                              border: "1px solid #dee2e6", // Ajout de bordures autour des cellules
                              padding: "10px",
                            }}
                          >
                            {marche.collecteur}
                          </td>
                          <td className="align-middle white-space-nowrap text-end pe-0">
                            <div className="btn-reveal-trigger position-static">
                              <button
                                className="btn btn-sm dropdown-toggle dropdown-caret-none transition-none btn-reveal fs-10"
                                type="button"
                                data-bs-toggle="dropdown"
                                data-boundary="window"
                                aria-haspopup="true"
                                aria-expanded="false"
                                data-bs-reference="parent"
                              >
                                ...
                              </button>
                              <div className="dropdown-menu dropdown-menu-end py-2">
                                <a
                                  className="dropdown-item"
                                  onClick={() => handleShow(marche)}
                                >
                                  Modifier
                                </a>
                                <div className="dropdown-divider" />
                                <a
                                  className="dropdown-item text-danger"
                                  onClick={() =>
                                    handleDeleteClick(marche.id_marche)
                                  }
                                >
                                  Supprimer
                                </a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" className="text-center">
                          Aucune forme trouvée.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-between mt-3">
                <ul className="pagination">
                  {Array.from({ length: totalPages }, (_, index) => (
                    <li key={index + 1} className="page-item">
                      <button
                        onClick={() => paginate(index + 1)}
                        className={`page-link ${
                          currentPage === index + 1 ? "active" : ""
                        }`}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {/* Modal d'ajout ou de modification */}
            {showModal && (
              <div
                className="modal fade show d-block"
                tabIndex="-1"
                role="dialog"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content border border-translucent">
                    <form
                      id="addFamilyForm"
                      autoComplete="off"
                      onSubmit={(e) => {
                        e.preventDefault();
                        soumettremarche();
                      }}
                    >
                      <div className="modal-header px-card border-0">
                        <div className="w-100 d-flex justify-content-between align-items-start">
                          <div>
                            <h5 className="mb-0 lh-sm text-body-highlight">
                              {selectedmarche
                                ? "Modifier Famille de Produit"
                                : "Ajouter une Famille de Produits"}
                            </h5>
                          </div>
                          <button
                            type="button"
                            className="btn p-1 fs-10 text-body"
                            onClick={handleClose}
                          >
                            Annuler
                          </button>
                        </div>
                      </div>

                      <div className="modal-body p-card py-0">
                        {/* Code Famille Produit */}
                        <div className="form-floating mb-3">
                          <input
                            className="form-control"
                            id="code"
                            type="text"
                            name="code"
                            value={code}
                            onChange={handleInputChange}
                            required
                            placeholder="Code marche"
                          />
                          <label htmlFor="code">Code marche</label>
                        </div>

                        {/* Nom Famille Produit */}
                        <div className="form-floating mb-3">
                          <input
                            className="form-control"
                            id="nom"
                            type="text"
                            name="nom"
                            value={nom}
                            onChange={handleInputChange}
                            required
                            placeholder="Nom"
                          />
                          <label htmlFor="nom">Nom</label>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            className="form-control"
                            id="superficie"
                            type="text"
                            name="superficie"
                            value={superficie}
                            onChange={handleInputChange}
                            required
                            placeholder="superficie"
                          />
                          <label htmlFor="superficie">Superficie</label>
                        </div>
                        <div className="form-floating mb-3">
                          <select
                            className="form-control"
                            id="type"
                            name="type"
                            value={type}
                            onChange={handleInputChange}
                            required
                          >
                            <option value="">Sélectionnez un type</option>
                            <option value="consommateurs">Consommateurs</option>
                            <option value="collecteurs">Collecteurs</option>
                            <option value="grossistes">Grossistes</option>
                          </select>
                          <label htmlFor="type">Type Marché</label>
                        </div>

                        <div className="form-floating mb-3">
                          <select
                            className="form-control"
                            id="jour"
                            name="jour"
                            value={jour}
                            onChange={handleInputChange}
                            required
                          >
                            <option value="">Sélectionnez un jour</option>
                            <option value="lundi">Lundi</option>
                            <option value="mardi">Mardi</option>
                            <option value="mercredi">Mercredi</option>
                            <option value="jeudi">Jeudi</option>
                            <option value="vendredi">Vendredi</option>
                            <option value="samedi">Samedi</option>
                            <option value="dimanche">Dimanche</option>
                            <option value="tous_les_jours">
                              Tous les jours
                            </option>
                          </select>
                          <label htmlFor="jour">Jour</label>
                        </div>

                        {/* Affichage Écran */}
                        <div className="form-floating mb-3">
                          <input
                            className="form-control"
                            type="text"
                            id="longitude"
                            name="longitude"
                            value={longitude}
                            onChange={handleInputChange}
                            placeholder="longitude"
                          />
                          <label htmlFor="nom">longitude</label>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            className="form-control"
                            type="text"
                            id="latitude"
                            name="latitude"
                            value={latitude}
                            onChange={handleInputChange}
                            placeholder="latitude"
                          />
                          <label htmlFor="latitude">Latitude</label>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            className="form-control"
                            type="text"
                            id="localite"
                            name="localite"
                            value={localite}
                            onChange={handleInputChange}
                            placeholder="Localite"
                          />
                          <label htmlFor="localite">Localite</label>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            className="form-control"
                            type="text"
                            id="description"
                            name="description"
                            value={description}
                            onChange={handleInputChange}
                            placeholder="Description"
                          />
                          <label htmlFor="description">Description</label>
                        </div>
                        <div className="form-floating mb-3">
                          <select
                            className="form-control"
                            id="commune"
                            name="commune"
                            value={selectedCommune}
                            onChange={(e) => setselectedCommune(e.target.value)} // Met à jour la famille sélectionnée
                            required
                          >
                            <option value="">Sélectionnez une commune</option>
                            {commune.map((com) => (
                              <option
                                key={com.id_commune}
                                value={com.id_commune}
                              >
                                {com.nom_commune}
                              </option>
                            ))}
                          </select>

                          <label htmlFor="famillesProduits">Commune</label>
                        </div>
                        <div className="form-floating mb-3">
                          <select
                            className="form-control"
                            id="commune"
                            name="commune"
                            value={selectedCollecteur}
                            onChange={(e) =>
                              setSelectedCollecteur(e.target.value)
                            } // Met à jour la famille sélectionnée
                            required
                          >
                            <option value="">Sélectionnez une commune</option>
                            {collecteur.map((collect) => (
                              <option key={collect.id} value={collect.nom}>
                                {collect.nom}
                              </option>
                            ))}
                          </select>

                          <label htmlFor="famillesProduits">Collecteur</label>
                        </div>
                      </div>

                      <div className="modal-footer d-flex justify-content-between align-items-center border-0">
                        <button
                          className="btn btn-primary px-4"
                          type="submit"
                          style={{
                            backgroundColor: "#00a600",
                          }}
                        >
                          {selectedmarche
                            ? "Modifier Famille"
                            : "Ajouter Famille"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default Marche;
